import { FC } from 'react';
import { Experience } from '../../types/experience';
import Paragraph from '../../components/Paragraph';

const Experiences: FC<Props> = ({ experience }) => {
  return (
    <div
      className='flex items-start py-8 border-t-4 border-black'
      key={experience.id}>
      <Paragraph text={experience.date} textSize='text-base' />
      <div className='flex flex-col ml-8'>
        <Paragraph
          text={experience.titleJob}
          textSize='text-xl lg:text-2xl'
          fontType='font-medium'
          setMargin='mb-4'
        />
        <Paragraph
          text={experience.company}
          textSize='text-sm'
          color='text-grey'
        />
      </div>
    </div>
  );
};

interface Props {
  experience: Experience;
}

export default Experiences;
