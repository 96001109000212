import { FC } from 'react';

const Heading: FC<Props> = (props) => {
  const { text, setMargin } = props;
  return (
    <h1
      className={`2xl:text-6xl lg:text-3xl text-2xl font-semibold text-black ${setMargin}`}>
      {text}
    </h1>
  );
};

interface Props {
  text: string | undefined;
  setMargin?: string;
}

export default Heading;
