import { FC, useRef, useState } from 'react';
import LinkIcon from '../components/LinkIcon';
import Paragraph from '../components/Paragraph';
import Label from '../components/Label';
import Button from '../components/Button';
import { Values } from '../types/values';
import { Formik, FormikHelpers } from 'formik';
import emailjs from '@emailjs/browser';
import Card from '../components/Card';

const FromContactContentModal: FC<Props> = ({ toggleModal }) => {
  const form = useRef<any>();
  const [appearAlert, setAppearAlert] = useState<boolean>(false);
  return (
    <>
      <LinkIcon
        icon='fa-xmark'
        typeIcon='fa-solid'
        backgroundColor='bg-transparent hover:bg-grey-lightest'
        iconSize='fa-lg'
        link='#contact'
        position='absolute right-10 top-8'
        onClick={toggleModal}
      />
      <div className='flex flex-col items-center w-full mb-12'>
        <Paragraph
          text='Get in touch!'
          fontType='font-medium'
          textSize='text-lg md:text-xl lg:text-2xl 2xl:text-4xl'
          setMargin='mb-4'
        />
        <Paragraph
          text='Contact me for a quote, help or to join the team.'
          textSize='text-sm lg:text-base'
          color='text-grey'
        />
      </div>
      <Formik
        initialValues={{
          name: '',
          phone: '',
          email: '',
          message: '',
        }}
        validate={(values) => {
          const errors: any = {};
          if (
            !values.email &&
            !values.message &&
            !values.name &&
            !values.phone
          ) {
            errors.email = 'Required';
            errors.name = 'Required';
            errors.phone = 'Required';
            errors.message = 'Required';
          } else if (!values.email) {
            errors.email = 'Required';
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Invalid email address';
          } else if (!values.name) {
            errors.name = 'Required';
          } else if (!values.phone) {
            errors.phone = 'Required';
          } else if (!values.message) {
            errors.message = 'Required';
          }
          return errors;
        }}
        onSubmit={(
          values: Values,
          { setSubmitting, resetForm }: FormikHelpers<Values>
        ) => {
          setTimeout(() => {
            emailjs
              .sendForm(
                'service_jigoehn',
                'template_gfs06mq',
                form.current,
                '7WsZMog8vhH148Nry'
              )
              .then(
                (result) => {
                  console.log(result);
                  resetForm();
                  setSubmitting(false);
                  setAppearAlert(!appearAlert);
                },
                (error) => {
                  console.log(error.text);
                }
              );
          }, 500);
        }}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form ref={form} onSubmit={handleSubmit}>
            <div className='flex flex-col w-full lg:flex-row mb-8'>
              <div className='flex w-full flex-col'>
                <div className='flex w-full flex-col mb-4 relative'>
                  <Label text='Name' />
                  <input
                    type={'text'}
                    name='name'
                    placeholder='Your Name'
                    className={`px-6 py-5 bg-grey-veryLight rounded-sm border-grey-veryLight focus:border-primary`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                  {errors.name && touched.name && (
                    <Paragraph
                      text={errors.name}
                      textSize={'text-xs'}
                      color='text-secondary-darkest'
                      position='absolute left-0 -bottom-4 animate__animated animate__fadeIn'
                    />
                  )}
                </div>
                <div className='flex w-full flex-col mb-4 relative'>
                  <Label text='Email' />
                  <input
                    type={'text'}
                    name='email'
                    placeholder='Your Email'
                    className={`px-6 py-5 bg-grey-veryLight rounded-sm border-grey-veryLight focus:border-primary`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {errors.email && touched.email && (
                    <Paragraph
                      text={errors.email}
                      textSize={'text-xs'}
                      color='text-secondary-darkest'
                      position='absolute left-0 -bottom-4 animate__animated animate__fadeIn'
                    />
                  )}
                </div>
                <div className='flex w-full flex-col mb-4 relative'>
                  <Label text='Phone' />
                  <input
                    type={'text'}
                    name='phone'
                    placeholder='Your Phone'
                    className={`px-6 py-5 bg-grey-veryLight rounded-sm border-grey-veryLight focus:border-primary`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phone}
                  />
                  {errors.phone && touched.phone && (
                    <Paragraph
                      text={errors.phone}
                      textSize={'text-xs'}
                      color='text-secondary-darkest'
                      position='absolute left-0 -bottom-4 animate__animated animate__fadeIn'
                    />
                  )}
                </div>
              </div>
              <div className='flex flex-col w-full lg:ml-4 relative'>
                <Label text='Message' />
                <textarea
                  name='message'
                  placeholder='Message'
                  className={`px-6 py-5 bg-grey-veryLight rounded-sm border-grey-veryLight focus:border-primary lg:h-72 resize-none `}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}></textarea>
                {errors.message && touched.message && (
                  <Paragraph
                    text={errors.message}
                    textSize={'text-xs'}
                    color='text-secondary-darkest'
                    position='absolute left-0 -bottom-0 animate__animated animate__fadeIn'
                  />
                )}
              </div>
            </div>
            <div className='flex justify-center w-full'>
              <Button text='Send message' typeSubmit disabled={isSubmitting} />
            </div>
            <div className='flex justify-center items-end mt-12'>
              <Card
                hidden={
                  appearAlert
                    ? 'block animate__animated animate__backInUp'
                    : 'hidden'
                }>
                <div className='flex items-center'>
                  <div className='w-10 h-10 bg-primary text-white rounded-full flex items-center justify-center'>
                    <i className='fa-solid fa-check'></i>
                  </div>
                  <Paragraph
                    text='Your message send succeeded!'
                    setMargin='ml-2'
                  />
                  <LinkIcon
                    icon='fa-xmark'
                    link='#'
                    typeIcon='fa-solid'
                    setMargin='ml-4'
                    iconSize='fa-xs'
                    dimensions='w-6 h-6'
                    backgroundColor='bg-transparent hover:bg-grey-veryLight'
                    onClick={() => {
                      setAppearAlert(!appearAlert);
                    }}
                  />
                </div>
              </Card>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

interface Props {
  toggleModal?: () => void;
}

export default FromContactContentModal;
