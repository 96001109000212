import { FC, useState } from 'react';
import Paragraph from '../../components/Paragraph';
import Image from '../../components/Image';
import { Skill } from '../../types/skills';
import Modal from '../../layout/Modal';
import LinkIcon from '../../components/LinkIcon';

const Skills: FC<Props> = ({ skill }) => {
  const [appearModal, setAppearModal] = useState<boolean>(false);

  const handelClick = () => {
    setAppearModal(!appearModal);
  };
  return (
    <>
      <div className='flex flex-col mr-12 mb-6'>
        <div
          className='w-24 h-24 rounded-full bg-black hover:bg-grey-dark flex justify-center items-center mb-2 cursor-pointer'
          id={`${skill.id}`}
          onClick={handelClick}>
          <Image src={skill.imageIcon} style={`w-8 h-8`} />
        </div>
        <Paragraph
          text={skill.field}
          textSize='text-lg xl:text-xl'
          fontType='font-medium'
        />
      </div>
      <Modal
        paddingCover='py-12 px-12 xl:py-24 xl:px-72 2xl:py-32 2xl:px-96'
        appearModal={appearModal}>
        <LinkIcon
          icon='fa-xmark'
          typeIcon='fa-solid'
          backgroundColor='bg-transparent hover:bg-grey-lightest'
          iconSize='fa-lg'
          link='#resume'
          position='absolute right-10 top-8'
          onClick={handelClick}
        />
        <div className='flex items-center mb-8'>
          <Image src={skill.imageTechs} style={`w-12 h-12`} />
          <Paragraph
            text={skill.field}
            textSize={'text-4xl'}
            fontType='font-medium'
            setMargin={'ml-4'}
          />
        </div>
        <div className='flex flex-col border-t-4 py-4 border-black'>
          {skill.technologies?.map((tech) => (
            <div className='flex items-center justify-between mb-4'>
              <div className='flex items-center'>
                <Image src={tech.imgTech} style={`w-8 h-8`} />
                <Paragraph
                  text={tech.nameTech}
                  textSize='text-lg'
                  setMargin='ml-4'
                />
              </div>
              <Paragraph
                text={tech.levelExperience}
                textSize='text-lg'
                color='text-grey-light'
              />
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

interface Props {
  skill: Skill;
}

export default Skills;
