import { FC } from 'react';
import Paragraph from '../../components/Paragraph';
import ButtonLink from '../../components/ButtonLink';

const LeftBio: FC = () => {
  const handelClick = (e: any) => {
    e.preventDefault();
    let direction = document.getElementById('portfolio');
    direction?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className='w-96 h-96 lg:w-72 lg:h-72 xl:w-96 xl:h-96 rounded-full relative bg-primary flex flex-col items-center justify-center pb-12'>
        <Paragraph
          text='View Last'
          color='text-white'
          textSize='text-sm lg:text-base xl:text-lg'
          setMargin='lg:ml-2'
        />
        <ButtonLink
          onClick={handelClick}
          href='#portfolio'
          text='Projects'
          icon='fa-arrow-up-long'
          setRotate='rotate-130'
          textColor='text-white'
          flexCol
        />
      </div>
      <div className='w-52 h-52 lg:w-44 lg:h-44 rounded-full xl:w-52 xl:h-52 bg-secondary flex flex-col items-center justify-center absolute left-10 md:left-20 lg:left-10 2xl:left-44'>
        <Paragraph
          text='Portfolio'
          color='text-white'
          textSize='text-base'
          fontType='font-normal'
        />
        <i className='fa-solid fa-briefcase fa-lg mt-4 text-white'></i>
      </div>
    </>
  );
};

export default LeftBio;
