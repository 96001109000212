import { FC } from 'react';

const ParagraphAbsolute: FC<Props> = (props) => {
  const { text, position = '-top-3 left-64 lg:-left-4' } = props;
  return (
    <p
      className={`text-sm text-secondary italic absolute ${position} -rotate-12`}>
      {text}
    </p>
  );
};

interface Props {
  text: string;
  position?: string;
}

export default ParagraphAbsolute;
