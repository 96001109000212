import { FC } from 'react';
import Image from '../../components/Image';
import Card from '../../components/Card';
import Paragraph from '../../components/Paragraph';

//@ts-ignore
import meSecond from '../../assets/images/me-second.png';
//@ts-ignore
import projectIcon from '../../assets/images/projectIcon.png';
//@ts-ignore
import freelanceIcon from '../../assets/images/freelanceIcon.png';
//@ts-ignore
import verificationIcon from '../../assets/images/verified-check-mark.png';

const LeftSide: FC = () => {
  return (
    <div className='col-span-5 lg:col-span-3 flex justify-center items-end relative'>
      <Image src={meSecond} style='w-full' />
      <Card absolute position='left-6 top-16 lg:top-32 2xl:left-32'>
        <div className='flex flex-col items-center mb-3'>
          <div className='flex justify-center items-center w-8 h-8 bg-primary-light rounded-full mb-2'>
            <Image src={projectIcon} style='w-4 w-4' />
          </div>
          <Paragraph text='Lead projects' textSize='text-sm' />
        </div>
        <Paragraph
          text='3+ Projects'
          textSize='text-base lg:text-sm xl:text-base 2xl:text-lg'
          fontType='font-semibold'
        />
        <Paragraph text='Creation from scratch' textSize='text-sm' />
      </Card>
      <Card absolute position='bottom-10 right-0'>
        <div className='flex items-center'>
          <div className='flex justify-center items-center w-8 h-8 bg-primary-lightest rounded-full mr-2'>
            <Image src={freelanceIcon} style='w-5 h-5' />
          </div>
          <Paragraph
            text='Work on most popular platforms'
            textSize='text-sm xl:text-base'
          />
        </div>
      </Card>
      <Image
        src={verificationIcon}
        style='w-5 h-5 md:w-6 md:h-6 2xl:w-12 2xl:h-12 absolute right-16 top-24 lg:top-52 xl:top-20 2xl:right-24 2xl:top-32'
      />
    </div>
  );
};

export default LeftSide;
