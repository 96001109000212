import { FC } from 'react';
import LinkIcon from '../../components/LinkIcon';
import Paragraph from '../../components/Paragraph';
import { Information } from '../../types/information';

const ContactInfo: FC<Props> = ({ contact }) => {
  return (
    <div
      className='py-8 flex items-center border-t-4 border-black'
      key={contact.id}>
      <LinkIcon
        icon={contact.icon}
        link='#'
        typeIcon={contact.iconType}
        textColor='text-black'
        backgroundColor='bg-white'
        setMargin='mr-8'
        dimensions='w-20 h-20'
      />
      <div className='flex flex-col'>
        <Paragraph
          text={contact.label}
          textSize='text-sm'
          setMargin='mb-2'
          color='text-grey'
        />
        <Paragraph
          text={contact.value}
          textSize='text-xl xl:break-words xl:w-64 2xl:break-normal 2xl:w-full'
          fontType='font-medium'
        />
      </div>
    </div>
  );
};

interface Props {
  contact: Information;
}

export default ContactInfo;
