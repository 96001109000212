import { FC } from 'react';

const Paragraph: FC<Props> = (props) => {
  const {
    text,
    textSize = 'xl:text-base',
    fontType = 'font-normal',
    color = 'text-black',
    setMargin,
    position,
  } = props;
  return (
    <p
      className={`${textSize} ${fontType} ${color} ${setMargin && setMargin} ${
        position && position
      }`}>
      {text}
    </p>
  );
};

interface Props {
  text: string | undefined;
  textSize?: string;
  fontType?: string;
  color?: string;
  setMargin?: string;
  position?: string;
}

export default Paragraph;
