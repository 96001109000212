import { FC } from 'react';

const Image: FC<Props> = (props) => {
  const { src, alt = 'image', style } = props;
  return <img src={src} alt={alt} className={style} />;
};

interface Props {
  src: string | undefined;
  alt?: string;
  style: string;
}

export default Image;
