import { FC } from 'react';
import Paragraph from '../../components/Paragraph';
import LinkIcon from '../../components/LinkIcon';
import Image from '../../components/Image';
import { Project } from '../../types/project';

const CardProject: FC<Props> = ({
  project,
  borderTop,
  buttonLinkHeader,
  footerLinks,
  cardStyleWhenInMainPage,
}) => {
  return (
    <div
      className={`flex flex-col mb-3 ${borderTop && 'border-t-4'} pt-4 ${
        cardStyleWhenInMainPage ? project.stylePage : project.style
      }`}>
      <div className='flex items-center justify-between mb-4'>
        <div className='flex flex-col'>
          <Paragraph
            text={project.title}
            textSize='text-base md:text-lg lg:text-2xl 2xl:text-4xl'
            fontType='font-medium'
          />
          <Paragraph
            text={project.typeProject}
            textSize='text-base md:text-lg lg:text-2xl 2xl:text-4xl'
            fontType='font-medium'
          />
        </div>
        {buttonLinkHeader && (
          <LinkIcon
            target='_blanc'
            typeIcon='fa-solid'
            rotate='rotate-45'
            link={project.linkPage}
            icon='fa-arrow-up-long'
            backgroundColor='bg-primary hover:bg-primary-dark'
            textColor='text-white'
          />
        )}
      </div>
      <div className='flex flex-col w-full mb-6'>
        <Paragraph
          color='text-grey'
          textSize='text-sm'
          text={project.techProject}
        />
        <Paragraph
          color='text-grey'
          textSize='text-sm'
          text={project.company}
        />
      </div>
      <div
        className={`flex w-full ${
          (project.link || project.sourceCode) && 'mb-4'
        }`}>
        <Image src={project.imageProject} style='w-full' />
      </div>
      {footerLinks && (
        <div className='flex w-full justify-end'>
          {project.sourceCode && (
            <a
              className='flex items-center text-black hover:underline hover:text-primary mr-4'
              target={'_blank'}
              href={project.sourceCode}>
              <i className='fa-brands fa-github'></i>
              <Paragraph
                text='See more'
                textSize='text-base'
                setMargin='ml-2'
                color='hover:text-primary'
              />
            </a>
          )}
          {project.link && (
            <a
              className='flex items-center text-black hover:underline hover:text-primary mr-4'
              target={'_blank'}
              href={project.link}>
              <Paragraph
                text='See more'
                textSize='text-base'
                setMargin='mr-2'
                color='hover:text-primary'
              />
              <i className='fa-solid fa-chevron-right'></i>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

interface Props {
  project: Project;
  borderTop?: boolean;
  buttonLinkHeader?: boolean;
  footerLinks?: boolean;
  cardStyleWhenInMainPage?: boolean;
}

export default CardProject;
