import { FC } from 'react';

const LinkIcon: FC<Props> = (props) => {
  const {
    icon,
    link,
    typeIcon = 'fa-brands',
    rotate,
    setMargin,
    dimensions = 'w-12 h-12',
    backgroundColor = 'bg-grey-veryLight hover:bg-grey-light',
    textColor = 'text-black',
    iconSize = 'fa-lg',
    position,
    onClick,
    target,
  } = props;
  return (
    <a
      onClick={onClick}
      href={link}
      target={target}
      className={`${textColor} rounded-full ${backgroundColor} ${dimensions} flex justify-center items-center ${
        position && position
      } ${setMargin && setMargin}`}>
      <i className={`${typeIcon} ${icon} ${iconSize} ${rotate && rotate}`}></i>
    </a>
  );
};

interface Props {
  icon: string | undefined;
  link: string | undefined;
  typeIcon?: string;
  rotate?: string;
  setMargin?: string;
  dimensions?: string;
  backgroundColor?: string;
  textColor?: string;
  iconSize?: string;
  position?: string;
  onClick?: (value?: any) => void;
  target?: string;
}

export default LinkIcon;
