import { FC } from 'react';

const Label: FC<Props> = (props) => {
  const { text } = props;
  return <label className='text-base mb-2'>{text}</label>;
};

interface Props {
  text: string;
}

export default Label;
