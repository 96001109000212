import { Project } from './types/project';
import { Experience } from './types/experience';
import { Skill } from './types/skills';
import { SocialMedia } from './types/social-media';
import { Information } from './types/information';
import { truncateString } from './utils/Truncate';

//@ts-ignore
import mtcProject from './assets/images/projects/MTC-project.png';
//@ts-ignore
import clubAdzProject from './assets/images/projects/ClubAdz-project.png';
//@ts-ignore
import GuitaProject from './assets/images/projects/Guita-project.png';
//@ts-ignore
import DevFutureProject from './assets/images/projects/dev-social.png';
//@ts-ignore
import AngularSiteProject from './assets/images/projects/angular-site.png';
//@ts-ignore
import GithubSearchProject from './assets/images/projects/github-search.png';
//@ts-ignore
import frontendIcon from './assets/images/FrontendIcon.png';
//@ts-ignore
import backendIcon from './assets/images/BackendIcon.png';
//@ts-ignore
import userExperienceDesign from './assets/images/UXDesignIcon.png';
//@ts-ignore
import developmentToolsIcon from './assets/images/DevToolsIcon.png';
//@ts-ignore
import usFlag from './assets/images/us.svg';
//@ts-ignore
import dzFlag from './assets/images/dz.svg';
//@ts-ignore
import saFlag from './assets/images/sa.svg';
//@ts-ignore
import fiverrImage from './assets/images/fiverr.png';
//@ts-ignore
import mostaqlImage from './assets/images/mostaql.png';
//@ts-ignore
import upworkImage from './assets/images/upwork.png';

//@ts-ignore
import frontendModal from './assets/images/technologies/frontend.png';
//@ts-ignore
import reactImg from './assets/images/technologies/react.png';
//@ts-ignore
import angularImg from './assets/images/technologies/angular.png';
//@ts-ignore
import materialUIImage from './assets/images/technologies/material-ui.png';
//@ts-ignore
import bootstrapImage from './assets/images/technologies/bootstrap.png';
//@ts-ignore
import cssImage from './assets/images/technologies/css.png';
//@ts-ignore
import javascriptImage from './assets/images/technologies/javascript.png';
//@ts-ignore
import tailwindCssImage from './assets/images/technologies/tailwindcss.png';
//@ts-ignore
import sassImage from './assets/images/technologies/sass.png';
//@ts-ignore
import reduxImage from './assets/images/technologies/redux.png';
//@ts-ignore
import reactNativeImage from './assets/images/technologies/react-native.png';
//@ts-ignore
import typeScriptImage from './assets/images/technologies/typescript.png';
//@ts-ignore
import backendModal from './assets/images/technologies/backend.png';
//@ts-ignore
import expressImage from './assets/images/technologies/express.png';
//@ts-ignore
import nodeImage from './assets/images/technologies/nodejs.png';
//@ts-ignore
import mongoDBImage from './assets/images/technologies/mongodb.png';
//@ts-ignore
import mySqlImage from './assets/images/technologies/mysql.png';
//@ts-ignore
import sqlServerImage from './assets/images/technologies/Microsoft_SQL_Server.png';
//@ts-ignore
import firebaseImage from './assets/images/technologies/firebase.png';
//@ts-ignore
import dotnetImage from './assets/images/technologies/dotnet.png';
//@ts-ignore
import ueImage from './assets/images/technologies/user-experience.png';
//@ts-ignore
import figmaImage from './assets/images/technologies/figma.png';
//@ts-ignore
import adobeXdImage from './assets/images/technologies/adobe-xd.png';
//@ts-ignore
import photoshopImage from './assets/images/technologies/adobe-photoshop.png';
//@ts-ignore
import illustratorImage from './assets/images/technologies/adobe-illustrator.png';
//@ts-ignore
import prototypeImage from './assets/images/technologies/prototype.png';
//@ts-ignore
import wireframesImage from './assets/images/technologies/wireframe.png';
//@ts-ignore
import visualDesignImage from './assets/images/technologies/visual-design.png';
//@ts-ignore
import toolsImage from './assets/images/technologies/tools.png';
//@ts-ignore
import vsCodeImage from './assets/images/technologies/visual-studio-code.png';
//@ts-ignore
import vsImage from './assets/images/technologies/visual-studio.png';
//@ts-ignore
import jiraImage from './assets/images/technologies/jira.png';
//@ts-ignore
import githubImage from './assets/images/technologies/github-icon.png';
//@ts-ignore
import gitlabImage from './assets/images/technologies/gitlab.png';
//@ts-ignore
import bitbucketImage from './assets/images/technologies/bitbucket.png';
//@ts-ignore
import sourcetreeImage from './assets/images/technologies/sourcetree.png';
//@ts-ignore
import gitImage from './assets/images/technologies/git-scm-icon.png';
//@ts-ignore
import npmImage from './assets/images/technologies/npm-icon.png';
//@ts-ignore
import yarnImage from './assets/images/technologies/yarn.png';
//@ts-ignore
import trelloImage from './assets/images/technologies/trello.png';
//@ts-ignore
import mysqlBrenchImage from './assets/images/technologies/mysqlbrench.png';
//@ts-ignore
import ila26Image from './assets/images/technologies/Ila-26.png';
//@ts-ignore
import felhanoutImage from './assets/images/technologies/felhanout.png';

export const portfolioProject: Array<Project> = [
  {
    id: 1,
    title: 'MTC Project',
    typeProject: 'Application/Website',
    techProject: 'React Native/React.js - Landing page Bootstrap',
    company: 'White Bay Limited',
    imageProject: mtcProject,
    style: 'xl:-mt-0',
    stylePage: 'xl:-mt-0',
    linkPage: 'https://mtcdev.whitebay.limited/',
    link: 'https://mtcdev.whitebay.limited/',
  },
  {
    id: 2,
    title: 'Club Adz',
    typeProject: 'Application/Website',
    techProject: 'MERN Stack - UI/UX design - Website design - React Native',
    company: 'Freelance - Fiverr/Mostaql',
    imageProject: clubAdzProject,
    style: 'xl:-mt-0',
    stylePage: 'xl:-mt-10 2xl:-mt-14',
    linkPage: 'https://billo.app/',
    link: 'https://billo.app/',
  },
  {
    id: 3,
    title: 'Guita',
    typeProject: 'UI/UX design',
    techProject: 'Figma design - wireframes - prototypes',
    company: 'Freelance - Fiverr',
    imageProject: GuitaProject,
    style: 'xl:-mt-0',
    stylePage: 'xl:-mt-20 2xl:-mt-28',
    linkPage: 'https://pancakeswap.finance/',
    link: 'https://pancakeswap.finance/',
  },
  {
    id: 7,
    title: 'Ila 26 Project',
    typeProject: 'Application/Website',
    techProject:
      'React.js - Syncfusion - Material UI - Azure - Redux Toolkit - React Query',
    company: 'Intelifox',
    imageProject: ila26Image,
    style: 'xl:-mt-0',
    stylePage: 'xl:-mt-0',
    linkPage: 'https://www.ila26.fr/',
    link: 'https://www.ila26.fr/',
  },
  {
    id: 8,
    title: 'Felhanout Project',
    typeProject: 'Application/Website',
    techProject:
      'React.js - React Native - Expo - Native Base - Redux Toolkit - React Query',
    company: 'Felhanout',
    imageProject: felhanoutImage,
    style: 'xl:-mt-0',
    stylePage: 'xl:-mt-0',
    linkPage: 'https://www.felhanout.dz/',
    link: 'https://www.felhanout.dz/',
  },
  {
    id: 4,
    title: 'Dev Future',
    typeProject: 'Application/Website',
    techProject: 'MERN stack - Bootstrap - Sass/Scss',
    company: 'Personal work',
    imageProject: DevFutureProject,
    style: 'xl:-mt-0',
    link: 'https://agile-cove-54128.herokuapp.com/',
    sourceCode: 'https://github.com/mohammed-esi/devFuture-react',
  },
  {
    id: 5,
    title: 'Angular Site',
    typeProject: 'Application/Website',
    techProject: 'Angular.js - Bootstrap - Typescript - JsonData',
    company: 'Personal work',
    imageProject: AngularSiteProject,
    style: 'xl:-mt-0',
    link: 'https://simple-app-angular.netlify.app/',
    sourceCode:
      'https://github.com/mohammed-esi/angular-simple-app-jsonplaceholder',
  },
  {
    id: 6,
    title: 'Github Search',
    typeProject: 'Application/Website',
    techProject: 'React.js - Context Api - Github Api - Bootstrap',
    company: 'Personal work',
    imageProject: GithubSearchProject,
    style: 'xl:-mt-0',
    link: 'https://github-search-users.netlify.app/',
    sourceCode: 'https://github.com/mohammed-esi/github-search-users-design',
  },
];

export const skills: Array<Skill> = [
  {
    id: 1,
    imageIcon: frontendIcon,
    field: 'Frontend Developer',
    imageTechs: frontendModal,
    technologies: [
      {
        imgTech: reactImg,
        nameTech: 'React.js',
        levelExperience: 'Experience',
      },
      {
        imgTech: angularImg,
        nameTech: 'Angular.js',
        levelExperience: 'Experience',
      },
      {
        imgTech: materialUIImage,
        nameTech: 'Material UI',
        levelExperience: 'Experience',
      },
      {
        imgTech: bootstrapImage,
        nameTech: 'Bootstrap',
        levelExperience: 'Expert',
      },
      {
        imgTech: cssImage,
        nameTech: 'CSS',
        levelExperience: 'Expert',
      },
      {
        imgTech: javascriptImage,
        nameTech: 'Javascript',
        levelExperience: 'Expert',
      },
      {
        imgTech: tailwindCssImage,
        nameTech: 'TailwindCSS',
        levelExperience: 'Experience',
      },
      {
        imgTech: sassImage,
        nameTech: 'SASS/LESS',
        levelExperience: 'Experience',
      },
      {
        imgTech: reduxImage,
        nameTech: 'Redux/Redux Toolkit',
        levelExperience: 'Experience',
      },
      {
        imgTech: reactNativeImage,
        nameTech: 'React Native',
        levelExperience: 'Experience',
      },
      {
        imgTech: typeScriptImage,
        nameTech: 'Typescript',
        levelExperience: 'Experience',
      },
    ],
  },
  {
    id: 2,
    imageIcon: backendIcon,
    field: 'Backend Developer',
    imageTechs: backendModal,
    technologies: [
      {
        imgTech: expressImage,
        nameTech: 'Express.js',
        levelExperience: 'Experience',
      },
      {
        imgTech: nodeImage,
        nameTech: 'Node.js',
        levelExperience: 'Experience',
      },
      {
        imgTech: mongoDBImage,
        nameTech: 'MongoDB',
        levelExperience: 'Experience',
      },
      {
        imgTech: mySqlImage,
        nameTech: 'Mysql',
        levelExperience: 'Entry-Level',
      },
      {
        imgTech: sqlServerImage,
        nameTech: 'Sql Server',
        levelExperience: 'Entry-Level',
      },
      {
        imgTech: firebaseImage,
        nameTech: 'Firebase',
        levelExperience: 'Entry-Level',
      },
      {
        imgTech: dotnetImage,
        nameTech: '.Net Core',
        levelExperience: 'Entry-Level',
      },
    ],
  },
  {
    id: 3,
    imageIcon: userExperienceDesign,
    field: 'User Experience Design',
    imageTechs: ueImage,
    technologies: [
      {
        imgTech: figmaImage,
        nameTech: 'Figma',
        levelExperience: 'Experience',
      },
      {
        imgTech: adobeXdImage,
        nameTech: 'Adobe XD',
        levelExperience: 'Experience',
      },
      {
        imgTech: photoshopImage,
        nameTech: 'Photoshop',
        levelExperience: 'Intermediate',
      },
      {
        imgTech: illustratorImage,
        nameTech: 'Adobe Illustrator',
        levelExperience: 'Intermediate',
      },
      {
        imgTech: prototypeImage,
        nameTech: 'Prototype',
        levelExperience: 'Experience',
      },
      {
        imgTech: wireframesImage,
        nameTech: 'Wireframe',
        levelExperience: 'Experience',
      },
      {
        imgTech: visualDesignImage,
        nameTech: 'Visual Design',
        levelExperience: 'Experience',
      },
    ],
  },
  {
    id: 4,
    imageIcon: developmentToolsIcon,
    field: 'Development Tools',
    imageTechs: toolsImage,
    technologies: [
      {
        imgTech: vsCodeImage,
        nameTech: 'Visual Studio Code',
        levelExperience: 'Experience',
      },
      {
        imgTech: vsImage,
        nameTech: 'Visual Studio',
        levelExperience: 'Experience',
      },
      {
        imgTech: jiraImage,
        nameTech: 'Jira',
        levelExperience: 'Experience',
      },
      {
        imgTech: githubImage,
        nameTech: 'Github',
        levelExperience: 'Experience',
      },
      {
        imgTech: gitlabImage,
        nameTech: 'Gitlab',
        levelExperience: 'Experience',
      },
      {
        imgTech: bitbucketImage,
        nameTech: 'Bitbucket',
        levelExperience: 'Intermediate',
      },
      {
        imgTech: sourcetreeImage,
        nameTech: 'Sourcetree',
        levelExperience: 'Intermediate',
      },
      {
        imgTech: gitImage,
        nameTech: 'Git',
        levelExperience: 'Experience',
      },
      {
        imgTech: npmImage,
        nameTech: 'Npm',
        levelExperience: 'Experience',
      },
      {
        imgTech: yarnImage,
        nameTech: 'Yarn',
        levelExperience: 'Experience',
      },
      {
        imgTech: trelloImage,
        nameTech: 'Trello',
        levelExperience: 'Experience',
      },
      {
        imgTech: mysqlBrenchImage,
        nameTech: 'Mysql Workbench',
        levelExperience: 'Entry-Level',
      },
    ],
  },
];

export const experience: Array<Experience> = [
  {
    id: 1,
    date: '2019/02 - Present',
    titleJob: 'Full-Stack Web Developer | UI/UX Designer - Web',
    company: 'Upwork - Fiverr - Mostaql',
  },
  {
    id: 2,
    date: '2023/12 - 2024/02',
    titleJob: 'Frontend developer (React Native)',
    company: 'Felhanout',
  },
  {
    id: 3,
    date: '2023/05 - 2024/01',
    titleJob: 'Mid-Level Frontend',
    company: 'Intelifox',
  },
  {
    id: 4,
    date: '2022/08 - 2023/05',
    titleJob: 'Frontend Developer',
    company: 'Intelifox',
  },
  {
    id: 5,
    date: '2022/02 - 2022/05',
    titleJob: 'Frontend Developer - Web/Mobile',
    company: 'White Bay Limited',
  },
];

export const contacts: Array<Information> = [
  {
    id: 1,
    label: 'Email',
    value: 'contact@mohammedelaminzighed.com',
    icon: 'fa-envelope',
    iconType: 'fa-solid',
  },
  {
    id: 2,
    label: 'Whatsapp',
    value: '+213791614832',
    icon: 'fa-whatsapp',
    iconType: 'fa-brands',
  },
];

export const socialNetworks: Array<SocialMedia> = [
  {
    id: 1,
    icon: 'fa-github',
    link: 'https://github.com/mohammed-esi',
  },
  {
    id: 2,
    icon: 'fa-linkedin-in',
    link: 'https://www.linkedin.com/in/mohammed-elamine-zighed/',
  },
  {
    id: 3,
    icon: 'fa-instagram',
    link: 'https://www.instagram.com/z_mohammed_elamine/',
  },
];

export const ratingData = [
  {
    id: 1,
    country: usFlag,
    platform: fiverrImage,
    clientName:
      window.screen.width <= 1024
        ? truncateString('mrt15', 15)
        : truncateString('mrt15', 20),
    contentText: truncateString('AmAzing work. Will hire again and again.', 24),
    rating: 5,
  },
  {
    id: 2,
    country: saFlag,
    platform: mostaqlImage,
    clientName:
      window.screen.width <= 1024
        ? truncateString('Abdulkarim Ibrahim', 15)
        : truncateString('Abdulkarim Ibrahim', 20),
    contentText: truncateString(
      'سلاسلة في العمل و تنفيد الافكار وتواصل واضح ومستمر انصح بالتعامع مع هذا المستقل، شكرا لك',
      24
    ),
    rating: 5,
  },
  {
    id: 3,
    country: dzFlag,
    platform: upworkImage,
    clientName:
      window.screen.width <= 1024
        ? truncateString('Brahim Hadj Mahammed', 15)
        : truncateString('Brahim Hadj Mahammed', 20),
    contentText: truncateString(
      'He is creative and i highly recommend it,flexible and attention to detail. you will get from him more than what you expect.',
      24
    ),
    rating: 5,
  },
  {
    id: 4,
    country: saFlag,
    platform: mostaqlImage,
    clientName:
      window.screen.width <= 1024
        ? truncateString('Abdulkarim Ibrahim', 15)
        : truncateString('Abdulkarim Ibrahim', 20),
    contentText: truncateString(
      'شخص متحمس و مبدع في عمله ومتفاهم.. انصح التعامل معه',
      24
    ),
    rating: 5,
  },
];

export const linkAccounts = [
  {
    link: 'https://www.upwork.com/freelancers/~01f09b57558d667eb3',
    namePlatform: 'Upwork',
    imagePlatform: upworkImage,
  },
  {
    link: 'https://www.fiverr.com/mohammed_elamin',
    namePlatform: 'Fiverr',
    imagePlatform: fiverrImage,
  },
  {
    link: 'https://mostaql.com/u/mohammed0564',
    namePlatform: 'Mostaql',
    imagePlatform: mostaqlImage,
  },
];
