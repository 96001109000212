import { FC } from 'react';

const ButtonWithHref: FC<Props> = (props) => {
  const { text, icon, setMargin, onClick, href } = props;
  return (
    <a
      href={href}
      className={`py-3 px-6 bg-primary hover:bg-primary-dark text-white font-medium text-base rounded-xl flex items-center ${
        setMargin && 'mr-4 xl:mr-12'
      }`}
      onClick={onClick}>
      {text}
      {icon && <i className={`fa-solid ${icon} fa-xs xl:fa-lg ml-4`}></i>}
    </a>
  );
};

interface Props {
  text: string;
  icon?: string;
  setMargin?: boolean;
  onClick?: (value?: any) => void;
  href?: string;
}

export default ButtonWithHref;
