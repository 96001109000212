import { FC } from 'react';
import LinkIcon from '../../components/LinkIcon';
import { SocialMedia } from '../../types/social-media';

const SocialNetwork: FC<Props> = ({ platform }) => {
  return (
    <LinkIcon
      key={platform.id}
      icon={platform.icon}
      link={platform.link}
      textColor='black'
      backgroundColor='bg-transparent'
      iconSize='fa-lg'
      setMargin='ml-4'
      target='_blanc'
    />
  );
};

interface Props {
  platform: SocialMedia;
}
export default SocialNetwork;
