import { FC } from 'react';
import { Project } from '../types/project';
import LinkIcon from '../components/LinkIcon';
import Paragraph from '../components/Paragraph';
import Grid from '../layout/Grid';
import CardProject from '../sections/portfolio/CardProject';

const PortfolioContentModal: FC<Props> = (props) => {
  const { portfolioProject, toggleModal } = props;
  return (
    <>
      <LinkIcon
        icon='fa-xmark'
        typeIcon='fa-solid'
        backgroundColor='bg-transparent hover:bg-grey-lightest'
        iconSize='fa-lg'
        link='#home'
        position='absolute right-10 top-8'
        onClick={toggleModal}
      />
      <Paragraph
        text='Portfolio'
        fontType='font-medium'
        textSize='text-base md:text-lg lg:text-2xl 2xl:text-4xl'
        setMargin='mb-12'
      />
      <Grid gridCol='grid-cols-6'>
        {portfolioProject.map((project) => (
          <div className='col-span-6 xl:col-span-2'>
            <CardProject project={project} key={project.id} footerLinks />
          </div>
        ))}
      </Grid>
    </>
  );
};

interface Props {
  portfolioProject: Array<Project>;
  toggleModal?: () => void;
}

export default PortfolioContentModal;
