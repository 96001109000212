import { FC } from 'react';

const Button: FC<Props> = (props) => {
  const { text, icon, setMargin, onClick, typeSubmit, disabled } = props;
  return (
    <button
      disabled={disabled}
      type={typeSubmit ? 'submit' : 'button'}
      className={`text-xs xl:text-lg py-3 px-6 rounded-xl text-white bg-primary hover:bg-primary-dark flex items-center ${
        setMargin && 'mr-4 xl:mr-12'
      }`}
      onClick={onClick}>
      <p className='inline'>{text}</p>
      {icon && <i className={`fa-solid ${icon} fa-xs xl:fa-lg ml-4`}></i>}
    </button>
  );
};

interface Props {
  text: string;
  icon?: string;
  setMargin?: boolean;
  onClick?: (value?: any) => void;
  typeSubmit?: boolean;
  disabled?: any;
}
export default Button;
