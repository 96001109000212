import { FC } from 'react';

const ButtonLink: FC<Props> = (props) => {
  const {
    text,
    icon,
    setMargin,
    setRotate = 'rotate-45',
    onClick,
    flexCol,
    textColor = 'text-black',
    position,
    textSize = 'text-sm xl:text-lg',
    fontType = 'font-medium',
    colorIcon = textColor,
    iconSize = 'fa-lg',
    href,
  } = props;
  return (
    <a
      href={href}
      onClick={onClick}
      className={`${textSize} ${textColor} ${fontType} hover:underline flex ${
        flexCol ? 'flex-col items-start' : 'items-center'
      } ${setMargin && 'mr-4 xl:mr-12'} ${position && position}`}>
      <p>{text}</p>
      {icon && (
        <i
          className={`fa-solid ${icon} ${iconSize} ${
            flexCol ? 'mt-4' : 'ml-2 lg:ml-4'
          } ${setRotate && setRotate} ${colorIcon}`}></i>
      )}
    </a>
  );
};

interface Props {
  text: string;
  textColor?: string;
  icon?: string;
  iconSize?: string;
  setMargin?: boolean;
  setRotate?: string;
  onClick?: (value?: any) => void;
  flexCol?: boolean;
  position?: string;
  textSize?: string;
  fontType?: string;
  colorIcon?: string;
  href?: string;
}

export default ButtonLink;
