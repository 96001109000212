import { FC } from 'react';
import LeftHeader from './LeftHeader';
import RightHeader from './RightHeader';
import Grid from '../../layout/Grid';
import PaddingSection from '../../components/PaddingSection';

const Header: FC = () => {
  return (
    <PaddingSection withoutPaddingBottom id='home'>
      <Grid>
        <div className='lg:col-span-2 col-span-5 flex flex-col relative justify-center'>
          <LeftHeader />
        </div>
        <div className='lg:col-span-3 col-span-5 flex justify-center relative'>
          <RightHeader />
        </div>
      </Grid>
    </PaddingSection>
  );
};
export default Header;
