import { FC, useState, useEffect } from 'react';
import './App.css';
import 'animate.css';
import Navbar from './layout/Navbar';
import Header from './sections/header/Header';
import Bio from './sections/bio/Bio';
import Reviews from './sections/reviews/Reviews';
import Portfolio from './sections/portfolio/Portfolio';
import Career from './sections/career/Career';
import Contact from './sections/contact/Contact';
import LinkIcon from './components/LinkIcon';

const App: FC = () => {
  const [buttonGoToTop, setButtonGoToTop] = useState<boolean>(false);
  const [scrollPosition, setSrollPosition] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleVisibleButton);
  });

  const handleVisibleButton = () => {
    console.log(scrollPosition);
    const position = window.pageYOffset;
    setSrollPosition(position);

    if (scrollPosition > 150) {
      return setButtonGoToTop(true);
    } else if (scrollPosition < 150) {
      return setButtonGoToTop(false);
    }
  };

  const handelClick = (e: any) => {
    e.preventDefault();
    let direction = document.getElementById('home');
    direction?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <Navbar />
      <Header />
      <Bio />
      <Reviews />
      <Portfolio />
      <Career />
      <Contact />
      {buttonGoToTop && (
        <LinkIcon
          onClick={handelClick}
          icon='fa-angle-up'
          link='#'
          typeIcon='fa-solid'
          backgroundColor='bg-primary hover:bg-primary-dark'
          textColor='text-white'
          position='fixed bottom-12 right-10'
        />
      )}
    </>
  );
};

export default App;
