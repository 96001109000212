import { FC } from 'react';
import PaddingSection from '../../components/PaddingSection';
import Grid from '../../layout/Grid';
import LeftSide from './LeftSide';
import RightSide from './RightSide';

const Reviews: FC = () => {
  return (
    <PaddingSection withoutPaddingBottom id='feedback'>
      <Grid>
        <div className='col-span-5 lg:col-span-2 flex flex-col justify-between'>
          <RightSide />
        </div>
        <div className='col-span-5 lg:col-span-3 flex justify-center items-end relative'>
          <LeftSide />
        </div>
      </Grid>
    </PaddingSection>
  );
};

export default Reviews;
