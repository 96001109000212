import { FC } from 'react';
import Heading from '../../components/Heading';
import Paragraph from '../../components/Paragraph';
import Card from '../../components/Card';
import Image from '../../components/Image';
import Link from '../../components/Link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { ratingData, linkAccounts } from '../../data';

// Import Swiper styles
import 'swiper/css';

const RightSide: FC = () => {
  return (
    <>
      <div className='mb-4'>
        <div className='mb-5'>
          <Heading text='My reviews' setMargin='mb-4' />
          <Paragraph
            text='Some reviews of my clients on freelance platforms, I worked on the most popular websites Fiverr,  
Upwork, and Mostaql.'
            color='text-grey'
            fontType='font-normal'
            textSize='text-sm'
          />
        </div>
        <div className='flex flex-col'>
          <Swiper
            className='mySwiper w-full'
            spaceBetween={12}
            slidesPerView={
              window.screen.width >= 1024 && window.screen.width < 1536 ? 1 : 2
            }
            loop
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}>
            {ratingData.map((rating) => (
              <SwiperSlide key={rating.id}>
                <Card
                  shadow='drop-shadow-sm'
                  setSpacing='mb-3'
                  border='border border-grey-lightest'>
                  <div className='flex w-full items-center'>
                    <Image
                      src={rating.country}
                      style='w-14 h-14 rounded-full mr-4'
                    />
                    <div className='flex w-full flex-col'>
                      <div className='flex items-center justify-between w-full'>
                        <Paragraph
                          text={rating.clientName}
                          fontType='font-semibold'
                          textSize='text-sm'
                        />
                        <Image
                          src={rating.platform}
                          style='w-6 h-6 rounded-full'
                        />
                      </div>
                      <Paragraph
                        text={rating.contentText}
                        textSize='text-sm'
                        setMargin='mb-2'
                      />
                      <div className='flex items-center'>
                        {[...Array(rating.rating)].map(() => (
                          <i className='fa-solid fa-star fa-sm text-yellow mr-1'></i>
                        ))}
                        <Paragraph
                          text='5'
                          fontType='font-medium'
                          color='text-yellow'
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className='flex items-center mb-12'>
        <Paragraph text='Hire me:' textSize='tex-sm' setMargin='mr-4' />
        {linkAccounts.map((account) => (
          <div className='flex items-center mr-3' key={account.namePlatform}>
            <Image src={account.imagePlatform} style='w-5 h-5' />
            <Link
              target='_blanc'
              text={account.namePlatform}
              href={account.link}
              setMargin='ml-2'
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default RightSide;
