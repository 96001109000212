import { FC, useState } from 'react';
import ButtonLink from '../../components/ButtonLink';
import Heading from '../../components/Heading';
import LineLeft from '../../components/LineLeft';
import LinkIcon from '../../components/LinkIcon';
import Paragraph from '../../components/Paragraph';
import ParagraphAbsolute from '../../components/ParagraphAbsolute';
import Modal from '../../layout/Modal';
import PortfolioContentModal from '../../containers/PortfolioContentModal';
import { portfolioProject } from '../../data';
import ButtonWithHref from '../../components/ButtonWithHref';

const LeftHeader: FC = () => {
  const [appearModal, setAppearModal] = useState<boolean>(false);

  const toggleModal = () => {
    setAppearModal(!appearModal);
  };

  const handelClick = (e: any) => {
    e.preventDefault();
    let direction = document.getElementById('contact');
    direction?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <div className='flex flex-col'>
        <div className='flex flex-col items-center lg:items-start relative mb-8'>
          <ParagraphAbsolute text='Hello!' />
          <Heading text='I am' />
          <Heading text='Mohammed Elamine Zighed' />
        </div>
        <div className='flex items-start mb-8'>
          <LineLeft />
          <Paragraph
            color='text-grey'
            textSize='text-sm lg:text-xs'
            text={`A person who caught fire coding, a freelancer, and a great employer. I worked on different types of projects from scratch or solve problems in web development and UI/UX.`}
          />
        </div>
        <div className='flex items-center justify-center md:justify-start mb-8'>
          <ButtonWithHref
            text={`Let's talk`}
            icon='fa-paper-plane'
            setMargin
            href='#contact'
            onClick={handelClick}
          />
          <ButtonLink
            text='Portfolio'
            icon='fa-arrow-up-long'
            onClick={toggleModal}
          />
        </div>
      </div>
      <div className='md:flex items-center absolute hidden md:right-0 md:bottom-8 lg:bottom-0 lg:left-0 xl:left-0 xl:bottom-12'>
        <Paragraph
          text='Check out my'
          textSize='xl:text-lg text-sm'
          setMargin='mr-12'
        />
        <LinkIcon
          icon='fa-github'
          link='https://github.com/mohammed-esi'
          setMargin='mr-6'
          target='_blank'
        />
        <LinkIcon
          icon='fa-instagram '
          link='https://www.instagram.com/z_mohammed_elamine/'
          setMargin='mr-6'
          target='_blank'
        />
        <LinkIcon
          icon='fa-linkedin-in'
          link='https://www.linkedin.com/in/mohammed-elamine-zighed-729706198/'
          setMargin='mr-6'
          target='_blank'
        />
      </div>
      <Modal appearModal={appearModal}>
        <PortfolioContentModal
          portfolioProject={portfolioProject}
          toggleModal={toggleModal}
        />
      </Modal>
    </>
  );
};

export default LeftHeader;
