import { FC, useState } from 'react';
import SectionWithBG from '../../components/SectionWithBG';
import PaddingSection from '../../components/PaddingSection';
import Grid from '../../layout/Grid';
import Paragraph from '../../components/Paragraph';
import ParagraphAbsolute from '../../components/ParagraphAbsolute';
import Heading from '../../components/Heading';
import LinkIcon from '../../components/LinkIcon';
import Image from '../../components/Image';
import Modal from '../../layout/Modal';
import ContactInfo from './ContactInfo';
import SocialNetwork from './SocialNetwork';
import FromContactContentModal from '../../containers/FromContactContentModal';
import { contacts, socialNetworks } from '../../data';

//@ts-ignore
import handIcon from '../../assets/images/handIcon.png';

const Contact: FC = () => {
  const [appearModal, setAppearModal] = useState<boolean>(false);

  const toggleModal = () => {
    setAppearModal(!appearModal);
  };
  return (
    <>
      <SectionWithBG id='contact'>
        <PaddingSection>
          <Grid>
            <div className='col-span-5 xl:col-span-2 flex flex-col justify-center mb-8'>
              <div className='flex flex-col relative mb-8'>
                <ParagraphAbsolute position='-left-4 -top-4' text='Contact!' />
                <Heading text='Let’s Discuss'></Heading>
                <Heading text='Your Project'></Heading>
              </div>
              {contacts.map((contact) => (
                <ContactInfo contact={contact} />
              ))}
            </div>
            <div className='col-span-5 xl:col-span-3 flex items-center justify-center mb-8'>
              <div className='w-128 h-128 2xl:w-156 2xl:h-156 border-2 rounded-full border-black relative'>
                <hr className='w-80 2xl:w-128 border-2 border-black absolute left-12 top-80 2xl:left-24 2xl:top-128 rotate-12' />
                <div className='flex items-end absolute left-24 top-60 2xl:left-36 2xl:top-96'>
                  <Image src={handIcon} style='w-14 h-14' />
                  <Paragraph
                    text='Say Hi!'
                    textSize='text-xl'
                    fontType='font-medium'
                    setMargin='ml-4'
                  />
                </div>
                <LinkIcon
                  icon='fa-arrow-up-long'
                  link='#contact'
                  typeIcon='fa-solid'
                  backgroundColor='bg-primary hover:bg-primary-dark'
                  textColor='text-white'
                  iconSize='fa-2xl'
                  rotate='rotate-45'
                  dimensions='w-32 h-32 2xl:w-52 2xl:h-52'
                  position='absolute right-12 top-48 2xl:right-36 2xl:top-72'
                  onClick={toggleModal}
                />
              </div>
            </div>
            <div className='col-span-5 flex flex-col lg:flex-row items-center justify-between pt-8 border-t-4 border-black'>
              <div className='flex items-center mb-2'>
                <i className='fa-regular fa-copyright mr-2'></i>
                <Paragraph
                  text='2022 All Right Reserved'
                  textSize='text-base'
                  fontType='font-medium'
                />
              </div>
              <Paragraph
                text='Created By Zighed Mohammed Elamine'
                textSize='text-base'
                fontType='font-medium'
                setMargin='mb-2'
              />
              <div className='flex items-center'>
                {socialNetworks.map((platform) => (
                  <SocialNetwork platform={platform} />
                ))}
              </div>
            </div>
          </Grid>
        </PaddingSection>
      </SectionWithBG>
      <Modal appearModal={appearModal} paddingCover='py-20 px-24'>
        <FromContactContentModal toggleModal={toggleModal} />
      </Modal>
    </>
  );
};

export default Contact;
