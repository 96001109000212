import { FC } from 'react';

const Link: FC<Props> = (props) => {
  const {
    text,
    textSize = 'text-sm',
    fontType = 'font-normal',
    setMargin,
    style = 'hover:underline',
    textColor = 'text-black',
    href,
    target,
  } = props;
  return (
    <a
      href={href}
      target={target && target}
      className={`${textSize} ${textColor} ${fontType} ${
        setMargin && setMargin
      } ${style}`}>
      {text}
    </a>
  );
};

interface Props {
  text: string;
  href: string;
  target?: string;
  textSize?: string;
  fontType?: string;
  setMargin?: string;
  style?: string;
  textColor?: string;
}

export default Link;
