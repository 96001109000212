import { FC } from 'react';
import SectionWithBG from '../../components/SectionWithBG';
import PaddingSection from '../../components/PaddingSection';
import LeftBio from './LeftBio';
import DescriptionBio from './DescriptionBio';
import Grid from '../../layout/Grid';

const Bio: FC = () => {
  return (
    <SectionWithBG id='bio'>
      <PaddingSection>
        <Grid>
          <div className='col-span-5 lg:col-span-2 flex justify-center my-4'>
            <LeftBio />
          </div>
          <div className='col-span-5 lg:col-span-3 my-4 flex flex-col justify-center'>
            <DescriptionBio />
          </div>
        </Grid>
      </PaddingSection>
    </SectionWithBG>
  );
};

export default Bio;
