import { FC } from 'react';
import PaddingSection from '../../components/PaddingSection';
import Grid from '../../layout/Grid';
import Heading from '../../components/Heading';
import LineLeft from '../../components/LineLeft';
import Paragraph from '../../components/Paragraph';
import ButtonLink from '../../components/ButtonLink';
import Skills from './Skills';
import Experiences from './Experiences';
import { experience, skills } from '../../data';
//@ts-ignore
import cvPdf from '../../assets/files/cv-mohammed-zighed.pdf';

const Career: FC = () => {
  const handelClick = (e: any) => {
    e.preventDefault();
    let direction = document.getElementById('contact');
    direction?.scrollIntoView({ behavior: 'smooth' });
  };
  const onDownloadPdf = () => {
    fetch(cvPdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'CV-Mohammed-Elamin-Zighed.pdf';
        alink.click();
      });
    });
  };
  return (
    <PaddingSection withoutPaddingBottom id='resume'>
      <Grid>
        <div className='col-span-5 xl:col-span-3'>
          <div className='flex flex-col mb-6'>
            <Heading text='Skills &' />
            <Heading text='Experience' />
          </div>
          <div className='flex items-start mb-4'>
            <LineLeft />
            <Paragraph
              color='text-grey'
              textSize='text-sm lg:text-xs'
              text={`Almost my skills orbit on Full-stack web development, I've skills as a frontend than backend developer 
          because I worked on a lot of projects as a frontend developer with both types ( Freelancer/Employer), with all 
          this also I can give you the best experience in UI/UX.
          A passion of mine solving problems through development to create modern and unique user interfaces.`}
            />
          </div>
          <div className='pl-7 lg:pl-8 mb-8 2xl:pl-10 2xl:mb-16'>
            <ButtonLink
              href='#resume'
              onClick={onDownloadPdf}
              text='Download CV'
              textSize='text-sm lg:text-xs'
              textColor='text-primary'
              fontType='font-semibold'
            />
          </div>
          <Paragraph
            text='Skills'
            textSize='text-base md:text-lg lg:text-2xl 2xl:text-4xl'
            fontType='font-medium'
            setMargin='mb-6'
          />
          <div className='flex flex-wrap items-center justify-center lg:justify-start'>
            {skills.map((skill) => (
              <Skills skill={skill} key={skill.id} />
            ))}
          </div>
        </div>
        <div className='col-span-5 xl:col-span-2'>
          <Paragraph
            text='Experience'
            textSize='text-base md:text-lg lg:text-2xl 2xl:text-4xl'
            fontType='font-medium'
            setMargin='mb-6'
          />
          {experience.map((exp) => (
            <Experiences experience={exp} key={exp.id} />
          ))}
        </div>
        <div className='col-span-5 flex justify-end'>
          <div className='w-60 h-60 rounded-full bg-primary flex items-center justify-center'>
            <div className='w-44 h-44 rounded-full bg-black flex items-center justify-center'>
              <div className='w-36 h-36 rounded-full border border-dashed border-primary flex items-center justify-center'>
                <ButtonLink
                  text='Hire me'
                  textColor='text-white'
                  icon='fa-arrow-up-long'
                  fontType='font-normal'
                  colorIcon='text-primary'
                  href='contact'
                  onClick={handelClick}
                />
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </PaddingSection>
  );
};

export default Career;
