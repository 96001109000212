import { FC, ReactElement, ReactNode } from 'react';

const Grid: FC<Props> = ({ children, gridCol = 'grid-cols-5' }) => {
  return <div className={`grid ${gridCol} gap-4`}>{children}</div>;
};

interface Props {
  gridCol?: string;
  children: JSX.Element | ReactElement | ReactNode;
}

export default Grid;
