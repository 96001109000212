/**
 * Returns a truncated string
 *
 * @param {string} string string to be truncated
 * @param {number} maxLength maximum number of characters
 * @returns {string}
 */
export const truncateString = (string: string, maxLength: number): string => {
  if (!string) {
    return '-';
  }
  return string.length > maxLength
    ? string.slice(0, maxLength - 1) + '…'
    : string;
};
/**
 * Returns a cleaned content from html tags
 *
 * @param {string} content  html content to be cleaned
 * @returns {string}
 */
export const trimHtmlContent = (content: string): string => {
  const regex = /(<([^>]+)>)/gi;
  return content.replace(regex, '');
};
