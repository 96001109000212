import { FC, ReactElement, ReactNode } from 'react';

const Card: FC<Props> = (props) => {
  const {
    children,
    padding = 'py-3 px-6',
    absolute,
    position,
    shadow = 'drop-shadow-md',
    border,
    setSpacing,
    hidden,
  } = props;
  return (
    <div
      className={`${padding} ${
        hidden && hidden
      } rounded-md ${shadow} ${setSpacing} bg-white ${border && border} ${
        absolute && `absolute ${position}`
      }`}>
      {children}
    </div>
  );
};

interface CommonProps {
  children: JSX.Element | ReactElement | ReactNode;
  hidden?: string;
  padding?: string;
  shadow?: string;
  border?: string;
  setSpacing?: string;
}

type ConditionalProps =
  | {
      absolute?: boolean;
      position: string;
    }
  | {
      absolute?: never;
      position?: string;
    };

type Props = CommonProps & ConditionalProps;

export default Card;
