import { FC } from 'react';
import Heading from '../../components/Heading';
import LineLeft from '../../components/LineLeft';
import Paragraph from '../../components/Paragraph';

const DescriptionBio: FC = () => {
  return (
    <>
      <Heading text='Who am I?' setMargin='mb-8' />
      <div className='flex items-start mb-8'>
        <LineLeft />
        <div className='flex flex-col'>
          <Paragraph
            color='text-grey'
            textSize='text-sm'
            setMargin='mb-4'
            text={`I love to dig into programming problems and solve them with modern technology. Mohammed is a full-stack web developer (Frontend more than backend) experienced in javascript/node.js, I mostly worked with Javascript during my career and have been focused on web software development and UI/UX design.
              `}
          />
          <Paragraph
            color='text-grey'
            textSize='text-sm'
            text={`My specialties include quickly learning new skills and programming languages, problem-solving, domain-driven design, responsive design principles, website optimization,  and the Model View View Model (MVVM) and Model View Controller (MVC) methods of organizing code.  So far I have javascript, Typescript, React.js, Express.js, Angular, C#,  .Net Framework, SQL, Github, Gitlab, and Git.
              `}
          />
        </div>
      </div>
    </>
  );
};

export default DescriptionBio;
