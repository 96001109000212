import { FC } from 'react';
import SectionWithBG from '../../components/SectionWithBG';
import ButtonLink from '../../components/ButtonLink';
import PaddingSection from '../../components/PaddingSection';
import Heading from '../../components/Heading';
import Grid from '../../layout/Grid';
import CardProject from './CardProject';
import { portfolioProject } from '../../data';
const Portfolio: FC = () => {
  const handelClick = (e: any) => {
    e.preventDefault();
    let direction = document.getElementById('home');
    direction?.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <SectionWithBG position='relative' id='portfolio'>
      <div className='absolute bottom-32 lg:bottom-24 right-12 lg:right-32 2xl:bottom-32 w-28 h-28 flex justify-center items-center rounded-full bg-primary'>
        <ButtonLink
          fontType='font-normal'
          text='See more'
          icon='fa-arrow-up-long'
          flexCol
          textColor='text-white'
          href='#home'
          onClick={handelClick}
        />
      </div>
      <PaddingSection>
        <div className='flex flex-col mb-3'>
          <Heading text='Portfolio' />
          <Heading text='Professional work' />
        </div>
        <Grid gridCol='grid-cols-6'>
          {portfolioProject.slice(0, 3).map((project) => (
            <div className='col-span-6 xl:col-span-2'>
              <CardProject
                project={project}
                borderTop
                buttonLinkHeader
                cardStyleWhenInMainPage
              />
            </div>
          ))}
        </Grid>
      </PaddingSection>
    </SectionWithBG>
  );
};

export default Portfolio;
