import { FC, ReactElement, ReactNode } from 'react';

const Modal: FC<Props> = (props) => {
  const {
    children,
    appearModal,
    paddingCover = 'py-8 px-10',
    relative,
  } = props;
  return (
    <div
      className={`${
        appearModal ? 'block' : 'hidden'
      } fixed top-0 left-0 right-0 z-50 w-full h-full overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full flex items-center justify-center ${paddingCover} animate__animated animate__fadeIn`}
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
      <div
        className={`bg-white w-full h-full rounded border border-grey-light shadow-sm py-10 px-6 overflow-y-scroll relative  animate__animated animate__bounceIn ${
          relative && relative
        }`}>
        {children}
      </div>
    </div>
  );
};

interface Props {
  children: JSX.Element | ReactElement | ReactNode;
  appearModal: boolean;
  paddingCover?: string;
  relative?: string;
}

export default Modal;
