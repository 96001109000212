import { FC } from 'react';
import Card from '../../components/Card';
import Paragraph from '../../components/Paragraph';

// Images
// @ts-ignore
import meImage from '../../assets/images/me.png';
// @ts-ignore
import achievementIcon from '../../assets/images/achievmentIcon.png';
// @ts-ignore
import toolsIcon from '../../assets/images/toolsIcon.png';
// @ts-ignore
import codeIcon from '../../assets/images/codeIcon.png';
// @ts-ignore
import meProfile from '../../assets/images/me-profile.jpg';

const RightHeader: FC = () => {
  return (
    <>
      <img src={meImage} alt='Me ...' className='w-1/2 lg:w-8/12' />
      <Card
        absolute
        position='2xl:left-24 2xl:top-32 xl:left-12 xl:top-20 lg:left-4 lg:top-32 left-0 top-12'>
        <div className='flex items-center mb-2  '>
          <img
            src={achievementIcon}
            alt='Achievement Icon'
            className='p-1.5 rounded-full bg-secondary-lightest mr-4 xl:w-6 xl:h-6 w-5 h-5'
          />
          <Paragraph
            text='Freelancer'
            textSize='xl:text-lg lg:text-sm'
            setMargin='mr-12'
          />
        </div>
        <Paragraph
          textSize='text-sm 2xl:text-2xl xl:text-xl lg:text-base'
          text='10+ Done'
          fontType='font-semibold'
          setMargin='mb-2'
        />
        <div className='flex items-center'>
          <Paragraph text='Always get' textSize='text-xs xl:text-sm' />
          <div className='flex items-center text-yellow ml-2'>
            <i className='fa-solid fa-star fa-sm mr-2 mb-1'></i>
            <Paragraph
              text='5 stars'
              textSize='xl:text-sm'
              fontType='font-medium'
              color='text-yellow'
            />
          </div>
        </div>
      </Card>
      <Card
        absolute
        position='2xl:-right-6 2xl:top-24 xl:-right-20 xl:top-48 lg:-right-24 lg:top-40 right-0 top-0'>
        <div className='flex items-center'>
          <img
            src={toolsIcon}
            alt='Achievement Icon'
            className='p-1.5 rounded-full bg-primary-lightest mr-4 xl:w-6 xl:h-6 w-5 h-5'
          />
          <Paragraph
            text='Modern skills & Fast learning'
            textSize='2xl:text-lg xl:text-sm lg:text-xs text-sm'
          />
        </div>
      </Card>
      <Card
        absolute
        position='2xl:left-48 2xl:bottom-28 xl:left-12 xl:bottom-10 lg:left-12 lg:bottom-6 left-0 bottom-10'>
        <div className='flex items-center'>
          <img
            src={codeIcon}
            alt='Achievement Icon'
            className='p-1.5 rounded-full bg-primary-lightest mr-4 xl:w-6 xl:h-6 w-5 h-5'
          />
          <Paragraph
            text='Developer & Designer'
            textSize='2xl:text-lg xl:text-sm lg:text-xs text-sm'
          />
        </div>
      </Card>
      <Card
        absolute
        position='2xl:top-96 2xl:right-20 xl:top-80 xl:-right-12 lg:top-60 lg:-right-24 top-48 right-0'>
        <div className='flex flex-col items-center'>
          <img
            src={meProfile}
            alt='Me Profile ...'
            className='w-16 h-16 xl:w-12 xl:h-12 lg:w-10 lg:h-10 object-cover	object-center rounded-full mb-4'
          />
          <Paragraph
            text='Professional Experience'
            fontType='font-medium'
            textSize='text-base xl:text-sm lg:text-xs'
          />
          <Paragraph
            text='Web developer & UI/UX'
            color='text-grey'
            textSize='text-base xl:text-sm lg:text-xs'
            setMargin='ml-12'
          />
        </div>
      </Card>
    </>
  );
};

export default RightHeader;
