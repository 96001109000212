import { FC, useState } from 'react';
// @ts-ignore
import logoLandingPage from '../assets/images/Logo-LandingPage.png';
import ButtonWithHref from '../components/ButtonWithHref';

const Navbar: FC = () => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);

  const toggleMobileMenu = (e: any) => {
    e.preventDefault();
    setMobileMenu(!mobileMenu);
  };

  const handelClick = (e: any) => {
    e.preventDefault();
    const scrollId = e.target.href.slice(e.target.href.indexOf('#') + 1);
    let direction = document.getElementById(scrollId);
    direction?.scrollIntoView({ behavior: 'smooth' });
  };

  const navbarItems: Array<any> = [
    {
      href: '#bio',
      name: 'About',
    },
    {
      href: '#feedback',
      name: 'Feedback',
    },
    {
      href: '#portfolio',
      name: 'Portfolio',
    },
    {
      href: '#resume',
      name: 'Resume',
    },
  ];

  return (
    <nav className='bg-transparent py-6 lg:px-32 md:px-16 md:relative'>
      {/* Mobile Navbar */}
      <div className='md:hidden flex justify-between items-center px-8'>
        <img src={logoLandingPage} alt='Logo' className='block w-9 h-9' />
        <button
          className='text-lg py-1 px-3 rounded-md text-black z-50'
          onClick={toggleMobileMenu}>
          <i
            className={`fa-solid animate__animated ${
              mobileMenu ? 'fa-xmark animate__rotateIn' : 'fa-bars'
            } fa-lg`}></i>
        </button>
      </div>
      <div
        className={`md:hidden fixed w-full h-full flex flex-col justify-center items-center rounded-md top-0 border border-grey-veryLight  bg-white outline-none overflow-x-hidden overflow-y-auto opacity-0 z-10 animate__animated ${
          mobileMenu && 'animate__fadeIn'
        }`}>
        {navbarItems.map((item) => (
          <a
            key={item.name}
            href={item.href}
            onClick={toggleMobileMenu}
            className='text-4xl my-6 focus:underline focus:text-primary focus:font-semibold'>
            {item.name}
          </a>
        ))}
      </div>
      {/* Main Navbar */}
      <div className='hidden md:flex justify-between items-cente'>
        <img src={logoLandingPage} alt='Logo' className='block w-9 h-9' />
        <div className='flex flex-wrap lg:space-x-12 md:space-x-6'>
          {navbarItems.map((item) => (
            <a
              key={item.name}
              href={item.href}
              onClick={handelClick}
              className='hover:underline lg:text-lg md:text-base sm:text-sm hover:text-primary hover:font-semibold'>
              {item.name}
            </a>
          ))}
        </div>
        <ButtonWithHref
          text='contact me'
          href='#contact'
          onClick={handelClick}
        />
      </div>
    </nav>
  );
};

export default Navbar;
