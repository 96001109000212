import { FC, ReactElement, ReactNode } from 'react';

const PaddingSection: FC<Props> = ({ children, withoutPaddingBottom, id }) => {
  return (
    <div
      id={id}
      className={`${
        withoutPaddingBottom ? 'pt-24' : 'py-32'
      } lg:px-32 md:px-16 sm:px-8`}>
      {children}
    </div>
  );
};

interface Props {
  children: JSX.Element | ReactElement | ReactNode;
  withoutPaddingBottom?: boolean;
  id?: string;
}

export default PaddingSection;
