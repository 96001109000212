import { FC, ReactElement, ReactNode } from 'react';

const SectionWithBG: FC<Props> = (props) => {
  const { children, position, id } = props;
  return (
    <div
      className={`w-full bg-primary-lightest ${position && position}`}
      id={id}>
      {children}
    </div>
  );
};

interface Props {
  children: JSX.Element | ReactElement | ReactNode;
  position?: string;
  id?: string;
}

export default SectionWithBG;
